<script setup lang="ts">
import { capitalize } from 'lodash'
import { useI18n } from 'vue-i18n'
import { VTabList } from '@/modules/shared/components'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'
import { computed, onMounted } from 'vue'
import { rails_url } from '@/modules/shared/utils/rails'
import { useOutstandingStore } from '@/modules/dashboard/stores/outstanding-store'

defineProps<{
  selectedTab: 'account' | 'esignatures' | 'files' | 'invoices' | 'notes' | 'overview' | 'portfolio' | 'taxes'
}>()

const { individual_id } = useRoute().params as { individual_id: string; slug: string }
const { t } = useI18n()
const authStore = useAuthStore()
const workspaceStore = useWorkspaceStore()
const outstandingStore = useOutstandingStore()
const outstanding = computed(() => outstandingStore.outstanding)

const individualURL = computed(() => `${rails_url()}/investors/${individual_id}`)

const taxYear = new Date().getFullYear() - 1

onMounted(async () => {
  await outstandingStore.fetchOutstandings(`individual:${individual_id}`)
})
</script>

<template>
  <div class="mb-12 px-4 sm:px-0">
    <VTabList
      :tabs="[
        {
          current: selectedTab === 'overview',
          name: capitalize(t('shared.overview')),
          to: {
            name: 'investing.individual-overview',
          },
        },
        {
          current: selectedTab === 'portfolio',
          name: capitalize(t('shared.portfolio', 0)),
          to: {
            name: 'investing.individual.portfolio',
          },
        },
        {
          current: selectedTab === 'invoices',
          name: capitalize(t('shared.invoice', 0)),
          to: {
            name: 'investing.individual-invoices',
          },
          count: outstanding.invoices_count,
        },
        {
          current: selectedTab === 'esignatures',
          name: t('shared.eSignature', 0),
          to: {
            name: 'investing.individual.documents',
          },
          count: outstanding.documents_count,
        },
        {
          current: selectedTab === 'files',
          name: capitalize(t('shared.file', 0)),
          to: `${individualURL}/files`,
        },
        {
          current: selectedTab === 'notes',
          name: capitalize(t('shared.note', 0)),
          visible: authStore.is_site_or_group_admin,
          to: `${individualURL}/notes`,
        },
        {
          current: selectedTab === 'taxes',
          name: capitalize(t('shared.tax', 0)),
          visible: workspaceStore.current?.has_managed_entities,
          to: {
            name: 'investing.individual.taxes',
            query: { year: taxYear },
            params: {
              individual_id: individual_id,
            },
          },
        },
        {
          current: selectedTab === 'account',
          name: capitalize(t('shared.account')),
          to: {
            name: 'investing.individual.account',
            params: {
              individual_id: individual_id,
            },
          },
        },
      ]"
    />
  </div>
</template>
