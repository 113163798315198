<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import IndividualLayout from '@/modules/investing/components/individuals/individual-layout.vue'
import VSelectInvestor from '@/modules/investing/components/VSelectInvestor.vue'
import { individual_entity_columns, individual_other_entity_columns } from '@/modules/investing/config/columns'
import { useIndividualStore } from '@/modules/investing/stores/better-individual-store'
import { VBadge, VSection, VStats, VStatsHero, VTable, VTabListSimple } from '@/modules/shared/components'
import { initialMoney } from '@/modules/shared/utils/money'
import { unflattenKey } from '@/modules/shared/utils/v-table'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'
import AllocationChart from '@/modules/shared/components/allocation-chart.vue'
import PerformerChart from '@/modules/shared/components/performer-chart.vue'

///////////////////////////////////////////////////////////////////////////////
// Calls
///////////////////////////////////////////////////////////////////////////////

const workspaceStore = useWorkspaceStore()
const authStore = useAuthStore()
const individualStore = useIndividualStore()
const individual = computed(() => individualStore.individual)

const selectedCids = computed(() =>
  individualStore.selectedShareholderKeys.map((keys) => JSON.parse(keys).reverse().join(':')),
)

///////////////////////////////////////////////////////////////////////////////
// Insights
///////////////////////////////////////////////////////////////////////////////

// collect and transform into camelCase
const individualInsights = computed(() => {
  const insights = individual.value?.insights

  if (!insights) {
    return {
      initialValue: initialMoney,
      currentValue: initialMoney,
      cashPosition: initialMoney,
      distributedNet: initialMoney,
      moic: 0,
      roi: 0,
      companies: [],
      industries: [],
    }
  }

  return {
    initialValue: insights.initial_value,
    currentValue: insights.current_value,

    cashPosition: insights.cash_position,
    distributedNet: insights.distributed_net,

    moic: insights.moic,
    roi: insights.roi,

    companies: insights.companies,
    industries: insights.industries,
  }
})

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

const { individual_id, slug } = useRoute().params as { individual_id: string; slug: string }

const fetch = async (shareholder_cids = null) => {
  showSkeleton()
  await individualStore.fetchIndividual(individual_id, { slug, shareholder_cids })

  if (shareholder_cids === null)
    shareholder_cids = individualStore.listShareholders.map((shareholder) => `${shareholder.type}:${shareholder.id}`)
  await Promise.all([
    individualStore.fetchEntities(individual.value.entity_ids, { slug, shareholder_cids }),
    individualStore.fetchOtherEntities(individual.value.other_entity_ids, { slug, shareholder_cids }),
  ])
  hideSkeleton()
}

const onFilter = async () => {
  await fetch(selectedCids.value)
}

///////////////////////////////////////////////////////////////////////////////
// Entities
///////////////////////////////////////////////////////////////////////////////

const entityTabs = ['Funds', 'SPVs', 'Associated Entities']
const entityTabIndex = ref(0)
const entityTabSelected = computed(() => entityTabs[entityTabIndex.value]?.toLowerCase())

const funds = computed(() => individualStore.listEntities.filter((entity) => entity.entity_type === 'fund'))
const spvs = computed(() => individualStore.listEntities.filter((entity) => entity.entity_type === 'spv'))
const otherEntities = computed(() => individualStore.listOtherEntities)

const isEntityAdmin = (entity) => {
  const admins_ids = unflattenKey(entity, 'admins').map((admin) => admin.id)

  return admins_ids.includes(parseInt(individual_id))
}

///////////////////////////////////////////////////////////////////////////////
// Portfolio Charts
///////////////////////////////////////////////////////////////////////////////

const portfolioChartTabIndex = ref(0)

const chartTabs = computed(() => {
  const tabs = ['Portfolio Allocation', 'Industry Allocation']
  if (workspaceStore.isDataAccessible('investments.roi', isAdmin.value)) tabs.push('Top Performers')
  return tabs
})

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const isAdmin = computed(() => true)
const is_viewing_own_profile = computed(() => individual_id === authStore.current_user.investor_id?.toString())
const is_entity_link_disabled = computed(() => !authStore.is_site_or_group_admin && !is_viewing_own_profile.value)

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { skeleton, hideSkeleton, showSkeleton } = useSkeleton()

onMounted(async () => {
  await fetch()
})
</script>

<template>
  <IndividualLayout selectedTab="overview">
    <VSection class="rounded border-l-4 border-[#85B5C9] bg-[#ABD0DF] px-2 py-3 sm:px-4">
      <div class="flex flex-col-reverse justify-start gap-2 sm:flex-row sm:items-center">
        <VSelectInvestor
          v-model="individualStore.selectedShareholderKeys"
          :investors="individualStore.listShareholders"
          :onClose="onFilter"
        />

        <p class="text-sm font-medium text-gray-700">
          Use this filter to view your portfolio as a specific investor or vehicle
        </p>
      </div>
    </VSection>

    <div class="gap-10 sm:flex">
      <VSection label="360° Portfolio overview" class="sm:w-1/2">
        <VStatsHero
          class="mb-6"
          :skeleton="skeleton"
          :currentValue="individualInsights.currentValue"
          :initialValue="individualInsights.initialValue"
          :hiddenFields="[
            !workspaceStore.isDataAccessible('investments.initial_value', isAdmin) ? 'initial' : null,
            !workspaceStore.isDataAccessible('investments.current_value', isAdmin) ? 'current' : null,
          ]"
        />
        <VStats
          :skeleton="skeleton"
          :stats="[
            {
              colspan: 2,
              label: 'ROI',
              type: 'percent',
              value: individualInsights.roi,
              visible: workspaceStore.isDataAccessible('investments.roi', isAdmin),
            },
            {
              colspan: 2,
              label: 'MOIC',
              type: 'multiple',
              value: individualInsights.moic,
              visible: workspaceStore.isDataAccessible('investments.moic', isAdmin),
            },
            {
              colspan: 2,
              label: 'Cash position',
              type: 'currency',
              value: individualInsights.cashPosition,
              visible: workspaceStore.isDataAccessible('investments.cash_position', isAdmin),
            },
            {
              colspan: 2,
              label: 'Distributed',
              type: 'currency',
              value: individualInsights.distributedNet,
              visible: workspaceStore.isDataAccessible('investments.distributions', isAdmin),
            },
          ]"
        />
      </VSection>

      <VSection class="sm:w-1/2">
        <VTabListSimple
          @update:current="(index: number) => (portfolioChartTabIndex = index)"
          :current="portfolioChartTabIndex"
          :tabs="chartTabs"
          class="-mt-2"
        />
        <div class="mt-6 rounded-lg border border-gray-200 bg-white p-2 sm:p-6">
          <AllocationChart
            v-if="portfolioChartTabIndex === 0"
            :items="individualInsights.companies"
            :skeleton="skeleton"
            :isAdmin="isAdmin"
          />
          <AllocationChart
            v-if="portfolioChartTabIndex === 1"
            :items="individualInsights.industries"
            :skeleton="skeleton"
            :isAdmin="isAdmin"
          />
          <PerformerChart
            v-if="portfolioChartTabIndex === 2 && workspaceStore.isDataAccessible('investments.roi', isAdmin)"
            :items="individualInsights.companies"
            :skeleton="skeleton"
            :total_roi="individualInsights.roi"
          />
        </div>
      </VSection>
    </div>

    <VSection>
      <VTabListSimple
        @update:current="(index: number) => (entityTabIndex = index)"
        :current="entityTabIndex"
        :tabs="entityTabs"
      />
      <template v-if="entityTabSelected !== 'associated entities'">
        <VTable
          :columns="individual_entity_columns"
          :items="entityTabSelected === 'spvs' ? spvs : funds"
          :name="`individuals-overview-${entityTabSelected}-Investor:${individual_id}`"
          :skeleton="skeleton"
          :slots="['name', 'admins', 'selected_subscriptions']"
        >
          <template #name="{ item: entity }">
            <div>
              <div class="mb-1 sm:w-[400px]">
                <RouterLink
                  class="cursor-pointer whitespace-break-spaces font-medium text-[#51889B] underline decoration-[#51889B]/50"
                  :to="{
                    name: 'investing.entity-overview',
                    params: { entity_id: entity.id, entity_type: entity.entity_type },
                  }"
                  v-if="!is_entity_link_disabled"
                >
                  {{ entity.name }}
                  {{ isEntityAdmin(entity) ? '(Admin)' : '' }}
                </RouterLink>
                <span class="whitespace-break-spaces font-medium" v-else>
                  {{ entity.name }}
                  {{ isEntityAdmin(entity) ? '(Admin)' : '' }}
                </span>
                <span class="ml-2 text-xs text-gray-300" v-if="entity.entity_status">•</span>
                <span class="ml-2 text-sm" v-if="entity.entity_status">
                  <VBadge class="w-fit" style="display: unset">In process</VBadge>
                </span>
              </div>
              <div class="flex flex-wrap items-center justify-start space-x-2">
                <div class="whitespace-normal text-sm text-gray-600" v-if="entity.legal_name">
                  {{ entity.legal_name }}
                </div>
              </div>
            </div>
          </template>
          <template #admins="{ item: entity }">
            <span v-for="(admin, index) in unflattenKey(entity, 'admins')" :key="admin.id">
              <RouterLink
                v-if="isAdmin"
                class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
                :to="{ name: 'investing.individual-overview', params: { individual_id: admin.id } }"
              >
                {{ admin.name }}
              </RouterLink>
              <span class="text-gray-900" v-else>{{ admin.name }}</span>
              <span v-if="index !== unflattenKey(entity, 'admins').length - 1">, </span>
            </span>
          </template>
          <template #selected_subscriptions="{ item: entity }">
            <div class="flex flex-col px-3 text-left text-sm text-gray-600">
              <a
                v-for="subscription in unflattenKey(entity, 'selected_subscriptions')"
                class="mb-1 inline-block w-fit whitespace-nowrap rounded bg-white px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-gray-300"
                :href="`/${entity['group.subdomain']}/billing/${entity.entity_type}/${entity.id}`"
              >
                <span class="pr-1.5">{{ subscription.name }}</span>
                <span class="border-l border-gray-300 pl-1.5">{{ subscription.status }}</span>
              </a>
            </div>
          </template>
        </VTable>
      </template>
      <template v-else>
        <VTable
          :columns="individual_other_entity_columns"
          :items="otherEntities"
          :name="`individuals-overview-${entityTabSelected}-Investor:${individual_id}`"
          :skeleton="skeleton"
          :slots="['admins', 'name']"
        >
          <template #admins="{ item: entity }">
            <span v-for="(admin, index) in unflattenKey(entity, 'admins')" :key="admin.id">
              <RouterLink
                v-if="isAdmin"
                class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
                :to="{ name: 'investing.individual-overview', params: { individual_id: admin.id } }"
              >
                {{ admin.name }}
              </RouterLink>
              <span class="text-gray-900" v-else>{{ admin.name }}</span>
              <span v-if="index !== unflattenKey(entity, 'admins').length - 1">, </span>
            </span>
          </template>
          <template #name="{ item }">
            <RouterLink
              v-if="!is_entity_link_disabled"
              class="cursor-pointer whitespace-break-spaces font-medium text-[#51889B] underline decoration-[#51889B]/50"
              :to="{ name: 'investing.other-entity.overview', params: { other_entity_id: item.id } }"
            >
              {{ item.name }}
            </RouterLink>
            <span class="text-gray-900" v-else>{{ item.name }}</span>
          </template>
        </VTable>
      </template>
    </VSection>
  </IndividualLayout>
</template>
