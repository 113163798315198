<script setup lang="ts">
import { useExtendedI18n } from '@/i18n'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useFilter } from '@/modules/core/composables/useFilter'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import IndividualLayout from '@/modules/investing/components/individuals/individual-layout.vue'
import VSelectInvestor from '@/modules/investing/components/VSelectInvestor.vue'
import { individual_invoice_columns } from '@/modules/investing/config/columns'
import { useIndividualStore } from '@/modules/investing/stores/better-individual-store'
import { investorPath } from '@/modules/investing/utils/investor'
import {
  ActionItem,
  ActionsGroup,
  ActionsMenu,
  VBadge,
  VButton,
  VButtonGroup,
  VModal,
  VSection,
  VTable,
  VTextArea,
  VTextField,
} from '@/modules/shared/components'
import { useModal } from '@/modules/shared/composables/use-modal'
import { useActionsMenu } from '@/modules/shared/utils/actions-menu'
import { createQueryString } from '@/modules/shared/utils/routing'
import { format } from '@/modules/shared/utils/v-table'
import { get } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useFetch as useFetchV3 } from '@/modules/shared/composables/use-fetch'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

const { n } = useExtendedI18n()
const route = useRoute()

const updateURLQuery = () => {
  const query = {}
  if (filters.value.type) {
    query.tab = filters.value.type
  }
  if (selectedCids.value.length !== individualStore.listShareholders.length) {
    query.investor = selectedCids.value.join(',')
  }
  const queryString = createQueryString(query)
  window.history.replaceState(null, null, `?${queryString}`)
}

///////////////////////////////////////////////////////////////////////////////
// Calls
///////////////////////////////////////////////////////////////////////////////

const authStore = useAuthStore()
const individualStore = useIndividualStore()
const individual = computed(() => individualStore.individual)

const selectedCids = computed(() =>
  individualStore.selectedShareholderKeys.map((keys) => JSON.parse(keys).reverse().join(':')),
)

const { filters, setFilter } = useFilter({
  type: null,
})

const filtered_invoices = computed(() => {
  return individualStore.listInvoices.filter((invoice) => {
    if (filters.value.type === 'outstanding') {
      return !invoice.marked_as_paid
    } else if (filters.value.type === 'paid') {
      return invoice.marked_as_paid
    }
    return true
  })
})

const outstanding_invoice_ids = computed(() =>
  individualStore.listInvoices.filter((invoice) => !invoice.marked_as_paid).map((invoice) => invoice.id),
)
const paid_invoice_ids = computed(() =>
  individualStore.listInvoices.filter((invoice) => invoice.marked_as_paid).map((invoice) => invoice.id),
)

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

const { individual_id, slug } = route.params as { individual_id: string; slug: string }

const fetch = async (shareholder_cids = null) => {
  showSkeleton()
  await individualStore.fetchIndividual(individual_id, { slug, shareholder_cids })

  if (shareholder_cids === null)
    shareholder_cids = individualStore.listShareholders.map((shareholder) => `${shareholder.type}:${shareholder.id}`)
  await Promise.all([individualStore.fetchInvoices(individual_id, { slug, shareholder_cids })])
  hideSkeleton()
}

const markInvoiceAsUnpaid = async (invoice) => {
  const confirm = window.confirm('Are you sure you want to mark the invoice as unpaid?')
  if (!confirm) return

  actionsMenu.setLoading('mark_as_unpaid', true)
  await useFetchV3(`/${slug}/investing/invoices/mark_multiple_as_unpaid`)
    .post({ invoice_ids: [invoice.id] })
    .json<{}>()
  await fetch(selectedCids.value)
  actionsMenu.setLoading('mark_as_unpaid', false)
}

const markInvoicesAsUnpaid = async () => {
  const invoice_ids = selectedInvoiceIds.value.filter((invoice_id) => paid_invoice_ids.value.includes(invoice_id))
  if (invoice_ids.length === 0) return alert('Please select at least one paid invoice to mark as unpaid')

  const confirm = window.confirm('Are you sure you want to mark the selected invoices as unpaid?')
  if (!confirm) return

  loading.value = true
  await useFetchV3(`/${slug}/investing/invoices/mark_multiple_as_unpaid`).post({ invoice_ids: invoice_ids }).json<{}>()
  await fetch(selectedCids.value)
  loading.value = false

  selectedInvoiceIds.value = []
}

const removeInvoice = async (invoice) => {
  const confirm = window.confirm('Are you sure you want to delete the invoice?')
  if (!confirm) return

  actionsMenu.setLoading('delete', true)
  await useFetchV3(`/${slug}/investing/invoice/${invoice.id}/remove`).delete().json<{}>()
  await fetch(selectedCids.value)
  actionsMenu.setLoading('delete', false)
}

const sendReminder = async (invoice) => {
  actionsMenu.setLoading('send_reminder', true)
  await useFetchV3(`/${slug}/investing/invoice/${invoice.id}/send_reminder`).post().json<{}>()
  actionsMenu.setLoading('send_reminder', false)
}

const submitInvoicePaymentForm = async () => {
  invoicePaymentForm.value.invoice_ids = selectedInvoiceIds.value.filter((invoice_id) =>
    outstanding_invoice_ids.value.includes(invoice_id),
  )
  const valid = await v$.value.$validate()
  if (!valid) return

  loading.value = true
  await useFetchV3(`/${slug}/investing/invoices/mark_multiple_as_paid`).post(invoicePaymentForm.value).json<{}>()
  await fetch(selectedCids.value)
  loading.value = false
  invoicePaymentFormModal.close()

  // reset
  invoicePaymentForm.value = { ...initialState }
  selectedInvoiceIds.value = []
}

const onFilter = async () => {
  await fetch(selectedCids.value)
}

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const is_viewing_own_profile = computed(() => individual_id === authStore.current_user.investor_id?.toString())
const isAdmin = computed(() => is_viewing_own_profile.value || authStore.is_site_or_group_admin)

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { skeleton, hideSkeleton, showSkeleton } = useSkeleton()
const actionsMenu = useActionsMenu(['send_reminder', 'mark_as_paid', 'mark_as_unpaid', 'delete'])

const selectedInvoiceIds = ref<number[]>([])
const mark_single_invoice = ref(false)
const selectedInvoice = ref(null)
const invoicePaymentFormModal = useModal()
const invoiceDetailsModal = useModal()
const loading = ref(false)

const openInvoiceDetails = (invoice_id) => {
  selectedInvoice.value = individualStore.getInvoice(invoice_id)
  window.history.replaceState(null, null, `?invoice_id=${invoice_id}`)
  invoiceDetailsModal.open()
}
const selectInvoiceType = (type: string | null) => {
  setFilter('type', type)
  updateURLQuery()
}
const toggleInvoiceSelection = (id: number) => {
  mark_single_invoice.value = false
  if (selectedInvoiceIds.value.includes(id)) {
    selectedInvoiceIds.value = selectedInvoiceIds.value.filter((invoiceId) => invoiceId !== id)
  } else {
    selectedInvoiceIds.value.push(id)
  }
}

// Form
const initialState = {
  invoice_ids: [],
  paid_at: null,
  instructions: null,
}
const rules = {
  invoice_ids: { required },
  paid_at: { required },
}
const invoicePaymentForm = ref({ ...initialState })
const v$ = useVuelidate(rules, invoicePaymentForm, { $lazy: true })

const markInvoiceAsPaid = (invoice) => {
  mark_single_invoice.value = true
  selectedInvoiceIds.value = [invoice.id]
  invoicePaymentFormModal.open()
}
const openInvoicePaymentForm = () => {
  mark_single_invoice.value = false
  const invoice_ids = selectedInvoiceIds.value.filter((invoice_id) =>
    outstanding_invoice_ids.value.includes(invoice_id),
  )
  if (invoice_ids.length === 0) return alert('Please select at least one outstanding invoice to mark as paid')
  invoicePaymentFormModal.open()
}

onMounted(async () => {
  await fetch()
  if (route.query.tab) setFilter('type', route.query.tab)
  if (route.query.invoice_id) openInvoiceDetails(route.query.invoice_id)
})
</script>

<template>
  <IndividualLayout selectedTab="invoices">
    <VSection class="flex items-center justify-between">
      <VButtonGroup>
        <VSelectInvestor
          v-model="individualStore.selectedShareholderKeys"
          :investors="individualStore.listShareholders"
          :onClose="onFilter"
        />
        <VButton :active="filters.type === null" class="w-32" @click="selectInvoiceType(null)"> All </VButton>
        <VButton :active="filters.type === 'outstanding'" class="w-32" @click="selectInvoiceType('outstanding')">
          Outstanding
        </VButton>
        <VButton :active="filters.type === 'paid'" class="w-32" @click="selectInvoiceType('paid')"> Paid </VButton>
      </VButtonGroup>
      <VButtonGroup v-if="isAdmin">
        <VButton size="sm" variant="v-blue" :loading="loading" :click="markInvoicesAsUnpaid"> Mark as Unpaid </VButton>
        <VButton size="sm" variant="v-blue" :disabled="loading" :click="openInvoicePaymentForm"> Mark as Paid </VButton>
      </VButtonGroup>
    </VSection>
    <VTable
      :columns="individual_invoice_columns(isAdmin)"
      :items="filtered_invoices"
      :name="`Investor-${individual_id}-invoices`"
      :skeleton="skeleton"
      :slots="['actions', 'investor.name', 'name', 'marked_as_paid', 'checkbox']"
    >
      <template #checkbox="{ item: invoice }">
        <input
          type="checkbox"
          :checked="selectedInvoiceIds.includes(invoice.id) && !mark_single_invoice"
          @change="toggleInvoiceSelection(invoice.id)"
        />
      </template>
      <template #investor.name="{ item: invoice }">
        <RouterLink
          class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
          :to="investorPath(get(invoice, 'investor._cid'))"
        >
          {{ get(invoice, 'investor.name') }}
        </RouterLink>
      </template>
      <template #name="{ item: invoice }">
        <div class="hyperlink" @click="() => openInvoiceDetails(invoice.id)">
          {{ get(invoice, 'name') }}
        </div>
      </template>
      <template #marked_as_paid="{ item: invoice }">
        {{ invoice.marked_as_paid ? 'paid' : 'outstanding' }}
      </template>
      <template #actions="{ item: invoice }">
        <div class="flex items-center justify-end space-x-1.5">
          <ActionsMenu v-if="isAdmin">
            <ActionsGroup>
              <ActionItem
                v-if="!invoice.marked_as_paid"
                text="Send Reminder"
                @click="() => sendReminder(invoice)"
                :loading="actionsMenu.actions.value.send_reminder?.loading"
              />
              <ActionItem
                v-if="!invoice.marked_as_paid"
                text="Mark as Paid"
                @click="() => markInvoiceAsPaid(invoice)"
              />
              <ActionItem
                v-else
                text="Mark as Unpaid"
                @click="() => markInvoiceAsUnpaid(invoice)"
                :loading="actionsMenu.actions.value.mark_as_unpaid?.loading"
              />
              <ActionItem
                text="Delete"
                @click="() => removeInvoice(invoice)"
                :loading="actionsMenu.actions.value.delete?.loading"
              />
            </ActionsGroup>
          </ActionsMenu>
        </div>
      </template>
    </VTable>
    <VModal :modalStore="invoicePaymentFormModal">
      <template #main>
        <VSection :label="mark_single_invoice ? 'Mark invoice as paid' : 'Mark multiple invoices as paid'">
          <form @submit.prevent="submitInvoicePaymentForm">
            <div class="mt-6 space-y-3">
              <VTextField
                v-model="invoicePaymentForm.paid_at"
                label="Payment Date"
                property="paid_at"
                ref="autofocus"
                type="date"
                :v$="v$"
              />
              <VTextArea
                label="Intructions / Notes"
                v-model="invoicePaymentForm.instructions"
                :v$="v$"
                property="instructions"
              />
            </div>
          </form>
        </VSection>
      </template>
      <template #footer>
        <div class="flex items-center justify-between space-x-3">
          <VButton :click="invoicePaymentFormModal.close" size="lg">Close</VButton>
          <VButton :click="submitInvoicePaymentForm" class="w-full" size="lg" :loading="loading" variant="primary">
            Submit
          </VButton>
        </div>
      </template>
    </VModal>
    <VModal :modalStore="invoiceDetailsModal" :has_footer="false">
      <template #main v-if="selectedInvoice">
        <div class="mb-5 text-2xl font-bold">{{ selectedInvoice.name }}</div>
        <h3 class="text-lg font-semibold leading-7 text-gray-700 dark:text-gray-300">Invoice Overview</h3>
        <div class="mb-3 mt-1 border-b-[1px] border-gray-300 pb-3">
          <span class="text-lg font-bold text-[#3b88af]">{{ n(selectedInvoice.total_amount, 'currency') }}</span>
          <span> due on {{ format(selectedInvoice.date, 'date') }}</span>
        </div>
        <table>
          <tbody>
            <tr>
              <td>Capital available for Investment</td>
              <!-- this should be amount, check list service -->
              <td class="font-semibold">{{ n(selectedInvoice.total_amount, 'currency') }}</td>
            </tr>
            <tr>
              <td>Management Fees</td>
              <td class="font-semibold">{{ n(selectedInvoice.management_fee, 'currency') }}</td>
            </tr>
            <tr>
              <td>Other Fees</td>
              <td class="font-semibold">{{ n(selectedInvoice.other_fee, 'currency') }}</td>
            </tr>
            <tr>
              <td>Total Due</td>
              <td class="font-semibold">{{ n(selectedInvoice.total_amount, 'currency') }}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                <VBadge
                  :color="selectedInvoice.marked_as_paid ? 'emerald' : 'red'"
                  class="ml-auto w-fit !rounded-full px-5 py-0.5 text-sm"
                  size="custom"
                >
                  {{ selectedInvoice.marked_as_paid ? 'Paid' : 'Unpaid' }}
                </VBadge>
              </td>
            </tr>
            <tr v-if="selectedInvoice.paid_at">
              <td>Paid Date</td>
              <td class="font-semibold">{{ format(selectedInvoice.paid_at, 'date') }}</td>
            </tr>
            <tr v-if="selectedInvoice.entity">
              <td>Entity</td>
              <td>
                <RouterLink
                  class="hyperlink"
                  :to="{
                    name: 'investing.entity-overview',
                    params: { entity_type: selectedInvoice.entity.entity_type, entity_id: selectedInvoice.entity.id },
                  }"
                  >{{ selectedInvoice.entity.name }}</RouterLink
                >
              </td>
            </tr>
            <tr>
              <td>Investor</td>
              <td>
                <RouterLink
                  class="hyperlink"
                  :to="{
                    name: 'investing.individual-overview',
                    params: { individual_id: selectedInvoice.investor.id },
                  }"
                >
                  {{ selectedInvoice.investor.name }}
                </RouterLink>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="selectedInvoice.wiring_instructions">
          <h3
            class="mb-3 mt-5 border-b-[1px] border-gray-300 pb-3 text-lg font-semibold leading-7 text-gray-700 dark:text-gray-300"
          >
            Wiring Instructions
          </h3>
          <div class="mb-2 whitespace-pre-line">{{ selectedInvoice.wiring_instructions }}</div>
        </div>
      </template>
    </VModal>
  </IndividualLayout>
</template>

<style scoped>
table td {
  @apply whitespace-nowrap pb-2 pr-5  first:w-[1%];
}
table td:nth-child(1) {
  @apply text-left;
}
table td:nth-child(2) {
  @apply text-right;
}
</style>
