<script setup lang="ts">
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import {
  RouterLinkBack,
  VAvatar,
  VButton,
  VButtonInvisible,
  VIcon,
  VMailTo,
  VPopUpModal,
  VSection,
  VSkeletonBar,
} from '@/modules/shared/components'
import { computed } from 'vue'
import { useIndividualStore } from '../stores/better-individual-store'
import { useRoute } from 'vue-router'
import { rails_url } from '@/modules/shared/utils/rails'
import {
  accreditatedStatusInfo,
  amlStatusInfo,
  profileStatusInfo,
  ProfileStatus,
  AMLStatus,
} from '../stores/investor-store'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'
import InvestorBadge from './investor-badge.vue'
import { useModal } from '@/modules/shared/composables/use-modal'
import { format } from '@/modules/shared/utils/v-table'

const { individual_id } = useRoute().params as { individual_id: string; slug: string }

///////////////////////////////////////////////////////////////////////////////
// Calls
///////////////////////////////////////////////////////////////////////////////

const authStore = useAuthStore()
const workspaceStore = useWorkspaceStore()
const individualStore = useIndividualStore()
const individual = computed(() => individualStore.individual)
const skeleton = computed(() => !individual.value)

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const isAdmin = computed(() => authStore.is_site_or_group_admin)

///////////////////////////////////////////////////////////////////////////////
// Statuses
///////////////////////////////////////////////////////////////////////////////

const accreditationBadge = computed(() => accreditatedStatusInfo[individual.value?.accredited_status])
const amlBadge = computed(() => amlStatusInfo[individual.value?.identity_log?.aml_status || AMLStatus.Default])
const profileBadge = computed(() => profileStatusInfo[individual.value?.profile_status])

///////////////////////////////////////////////////////////////////////////////
// AML
///////////////////////////////////////////////////////////////////////////////

const amlModal = useModal()
const can_open_aml_modal = computed(
  () => workspaceStore.current.options.enable_investor_aml_verification && authStore.is_site_or_group_admin,
)
const openAML = () => {
  if (!can_open_aml_modal.value) return
  amlModal.open()
}

const getAmlStatus = computed(() => {
  let status = individual.value?.identity_log?.is_whitelisted
    ? 'whitelisted'
    : individual.value?.identity_log?.match_status

  switch (status) {
    case 'false_positive':
    case 'no_match':
    case 'true_positive_reject':
    case 'unknown':
    case 'whitelisted':
      return 'pass'
    case 'potential_match':
      return 'potential_match'
    case 'true_positive':
    case 'true_positive_approve':
      return 'match'
  }

  return 'default'
})

const updateAML = async (match_status: string) => {
  const payload = {
    identity_log_id: individual.value.identity_log.id,
    individual_id: individual.value.id,
    match_status: match_status,
  }
  // TODO
  // await investingInvestorStore.updateIndividualAML(payload)
  // individual.value = investingInvestorStore.items.get(cid)
  amlModal.close()
}

///////////////////////////////////////////////////////////////////////////////
// Accreditation
///////////////////////////////////////////////////////////////////////////////

const accreditedModal = useModal()
</script>

<template>
  <header class="mb-6 px-4 sm:px-0">
    <div class="-mt-8 mb-6">
      <RouterLinkBack
        v-if="isAdmin"
        :to="{ name: 'investing.individuals' }"
        :fallback="{ name: 'investing.individual-overview' }"
      >
        <VButtonInvisible>← Back to all Individuals</VButtonInvisible>
      </RouterLinkBack>
    </div>

    <div class="flex items-center gap-5">
      <RouterLink :to="{ name: 'investing.individual.edit', params: { individual_id: individual_id } }">
        <VAvatar :image="individual?.profile_picture" :initials="individual?.initials" size="lg" />
      </RouterLink>
      <div class="flex max-w-[70%] flex-col gap-3">
        <VSkeletonBar v-if="skeleton" />

        <h2
          v-if="!skeleton"
          class="whitespace-nowrap text-3xl font-semibold leading-7 text-gray-900 dark:text-gray-300"
        >
          {{ individual?.name }}
        </h2>
        <div v-if="individual?.email" class="-mt-2">
          <VSkeletonBar v-if="skeleton" />
          <VMailTo v-else :email="individual?.email" class="incognito text-sm" />
        </div>
        <div class="flex items-center justify-start space-x-1.5 text-xs">
          <template v-if="skeleton">
            <VSkeletonBar />
            <VSkeletonBar />
            <VSkeletonBar />
          </template>
          <template v-else>
            <a
              :href="`${rails_url()}/investors/${individual?.id}/edit/accreditation`"
              v-if="accreditationBadge"
              class="cursor-pointer"
            >
              <InvestorBadge :color="accreditationBadge.color">{{ accreditationBadge.label }}</InvestorBadge>
            </a>

            <InvestorBadge
              v-if="amlBadge && workspaceStore.current.options.is_aml_enabled"
              @click="openAML"
              :color="amlBadge.color"
              :class="{ 'cursor-pointer': can_open_aml_modal }"
              >{{ amlBadge.label }}</InvestorBadge
            >
            <template v-if="profileBadge">
              <RouterLink
                :to="{ name: 'investing.individual.edit', params: { individual_id: individual_id } }"
                v-if="individual.profile_status === ProfileStatus.Incomplete"
              >
                <InvestorBadge :color="profileBadge.color">{{ profileBadge.label }}</InvestorBadge>
              </RouterLink>
              <InvestorBadge :color="profileBadge.color" v-else>{{ profileBadge.label }}</InvestorBadge>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div class="mb-6 sm:mb-0">
      <div class="flex items-center justify-end space-x-2">
        <slot name="actions"></slot>
      </div>
    </div>

    <VPopUpModal :modalStore="accreditedModal"> Accredited Info </VPopUpModal>
    <VPopUpModal :modalStore="amlModal">
      <VSection label="AML Verification">
        <div class="text-gray-500">AML Status</div>
        <div class="flex gap-1">
          <template v-if="getAmlStatus === 'pass'">
            <span class="text-[rgb(64,146,126)]">
              <VIcon name="shield-tick" class="block h-[18px] w-[18px]" />
            </span>
          </template>
          <template v-if="getAmlStatus === 'default'">
            <span class="text-gray-300">
              <VIcon name="hourglass-01" />
            </span>
          </template>
          <template v-if="getAmlStatus === 'potential_match'">
            <span class="text-orange-400">
              <VIcon name="flag-02" />
            </span>
          </template>
          <template v-if="getAmlStatus === 'match'">
            <span class="text-red-600">
              <VIcon name="slash-circle-01" />
            </span>
          </template>
          <a
            class="hyperlink"
            :href="individual.identity_log.share_url"
            target="_blank"
            v-if="individual?.identity_log?.share_url"
          >
            {{ amlBadge?.label }}
          </a>
          <div v-else>{{ amlBadge?.label }}</div>
        </div>
        <div class="mt-5 text-gray-500">AML Last check</div>
        <div class="flex gap-1">
          <template v-if="individual?.identity_log?.updated_at">
            {{ format(individual?.identity_log?.updated_at, 'date') }},
            {{ format(individual?.identity_log?.updated_at, 'time') }}
          </template>
          <template v-else>None</template>
        </div>

        <div class="mt-5 flex w-fit flex-col gap-3" v-if="getAmlStatus !== 'pass' && authStore.is_site_or_group_admin">
          <VButton variant="v-green" size="sm" :click="() => updateAML('false_positive')"
            >Mark as Pass - No match found</VButton
          >
          <VButton variant="v-red" size="sm" :click="() => updateAML('true_positive')" v-if="getAmlStatus !== 'match'">
            Mark as Fail - Match found
          </VButton>
        </div>
      </VSection>
    </VPopUpModal>
  </header>
</template>
