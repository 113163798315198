<script setup lang="ts">
// vue
import { computed, onMounted, ref } from 'vue'
import { RouterLink, useRoute } from 'vue-router'

// third-party
import { get } from 'lodash'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

// stores
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { useEntityStore } from '@/modules/investing/stores/better-entity-store'
import { useCallStore } from '@/modules/investing/stores/call-store'
import { useFetch as useFetchV3 } from '@/modules/core/stores/fetch'
import { useFetch as useFetchV2 } from '@/modules/shared/composables/use-fetch'

// composables
import { useFilter } from '@/modules/core/composables/useFilter'
import { useSkeleton } from '@/modules/core/composables/useSkeleton'
import { useModal } from '@/modules/shared/composables/use-modal'

// local components
import CallsBarChart from '@/modules/investing/components/calls-bar-chart.vue'
import VSelectInvestor from '@/modules/investing/components/VSelectInvestor.vue'
import EntityLayout from '@/modules/investing/components/entities/entity-layout.vue'

// shared components
import {
  ActionsGroup,
  ActionItem,
  ActionsMenu,
  VBadge,
  VButton,
  VButtonGroup,
  VIcon,
  VModal,
  VSection,
  VTable,
  VTextArea,
  VTextField,
} from '@/modules/shared/components'

// config
import { call_columns } from '@/modules/investing/config/columns'
import { initialMoney, sum } from '@/modules/shared/utils/money'

// utils
import { investorPath } from '@/modules/investing/utils/investor'
import { rails_url } from '@/modules/shared/utils/rails'
import { format } from '@/modules/shared/utils/v-table'
import { useActionsMenu } from '@/modules/shared/utils/actions-menu'

///////////////////////////////////////////////////////////////////////////////

const route = useRoute()

///////////////////////////////////////////////////////////////////////////////
// Calls
///////////////////////////////////////////////////////////////////////////////

const entityStore = useEntityStore()
const entity = computed(() => entityStore.entity)

const selectedCids = computed(() =>
  entityStore.selectedInvestorKeys.map((keys) => JSON.parse(keys).reverse().join(':')),
)

// Expansion allows us to add additional data to the call for the VTable component
// This is currently required in order to search and sort by expanded data
// NOTE run this before filtering to reduce the number of times we run this function
// NOTE expansion could be handled by the API, however, doing it client side simplifies the API
// QUESTION should we move this to the store?
const expandedCalls = computed(() => {
  return entityStore.listCalls.map((call) => {
    return {
      ...call,
      shareholder: entityStore.getShareholder(call.shareholder_id, call.shareholder_type),
    }
  })
})

const { filters, setFilter } = useFilter({
  type: null, // 'null', 'sent', 'scheduled'
  minCalled: null,
})

const filteredCalls = computed(() => {
  return expandedCalls.value.filter((call) => {
    if (filters.value.type === 'sent' && call.status !== 'sent') {
      return false
    }

    if (filters.value.type === 'scheduled' && call.status !== 'unsent') {
      return false
    }

    if (filters.value.minCalled !== null && call.called.amount < filters.value.minCalled) {
      return false
    }

    return true
  })
})

const batchCalls = computed(() => {
  const batches = {}

  filteredCalls.value.forEach((call) => {
    let batchCall = batches[call.batch_id] || {}

    if (!batchCall.id) {
      batchCall.id = call.batch_id
      batchCall.name = `${format(call.due_date, 'date')}`
      batchCall.due_date = call.due_date
      batchCall.capital = call.capital
      batchCall.management_fee = call.management_fee
      batchCall.other_fee = call.other_fee
      batchCall.called = call.called
      batchCall.type = call.type
      batchCall.calls = [call]
    }

    if (!batchCall.calls.find((c) => c.id === call.id)) {
      batchCall.capital = sum([batchCall.capital, call.capital])
      batchCall.management_fee = sum([batchCall.management_fee, call.management_fee])
      batchCall.other_fee = sum([batchCall.other_fee, call.other_fee])
      batchCall.called = sum([batchCall.called, call.called])
      batchCall.calls.push(call)
    }

    batches[call.batch_id] = { ...batchCall }
  })

  return Object.values(batches)
})

// collect and transform into camelCase
const entityInsights = computed(() => {
  const insights = entityStore.entity?.insights

  if (!insights) {
    return {
      initialValue: initialMoney,
      currentValue: initialMoney,
      commitment: initialMoney,
      committed: initialMoney,
      commitmentRemaining: initialMoney,
      cashPosition: initialMoney,
      hurdleRemaining: initialMoney,
      calledOutstandingTotal: initialMoney,
      calledOutstandingCapital: initialMoney,
      calledOutstandingManagementFee: initialMoney,
      calledOutstandingOtherFee: initialMoney,
      calledSettledTotal: initialMoney,
      calledSettledCapital: initialMoney,
      calledSettledManagementFee: initialMoney,
      calledSettledOtherFee: initialMoney,
      distributedCarriedInterest: initialMoney,
      distributedHurdleRemaining: initialMoney,
      distributedInterest: initialMoney,
      distributedNet: initialMoney,
      distributedOtherFee: initialMoney,
      distributedPreferredReturn: initialMoney,
      distributedProfit: initialMoney,
      distributedReturnOfCapital: initialMoney,
      dpi: 0,
      moic: 0,
      roi: 0,
      rvpi: 0,
      tvpi: 0,
      xirr: 0,
    }
  }

  return {
    initialValue: insights.initial_value,
    currentValue: insights.current_value,

    commitment: insights.commitment,
    committed: insights.committed,
    commitmentRemaining: insights.commitment_remaining,
    cashPosition: insights.cash_position,
    hurdleRemaining: insights.hurdle_remaining,

    calledOutstandingTotal: insights.called_outstanding_total,
    calledOutstandingCapital: insights.called_outstanding_capital,
    calledOutstandingManagementFee: insights.called_outstanding_management_fee,
    calledOutstandingOtherFee: insights.called_outstanding_other_fee,
    calledSettledTotal: insights.called_settled_total,
    calledSettledCapital: insights.called_settled_capital,
    calledSettledManagementFee: insights.called_settled_management_fee,
    calledSettledOtherFee: insights.called_settled_other_fee,

    distributedCarriedInterest: insights.distributed_carried_interest,
    distributedHurdleRemaining: insights.distributed_hurdle_remaining,
    distributedInterest: insights.distributed_interest,
    distributedNet: insights.distributed_net,
    distributedOtherFee: insights.distributed_other_fee,
    distributedPreferredReturn: insights.distributed_preferred_return,
    distributedProfit: insights.distributed_profit,
    distributedReturnOfCapital: insights.distributed_return_of_capital,

    dpi: insights.dpi,
    moic: insights.moic,
    roi: insights.roi,
    rvpi: insights.rvpi,
    tvpi: insights.tvpi,
    xirr: insights.xirr,
  }
})

const commitmentTotals = computed(() => {
  return {
    committed: entityInsights.value.committed,
    commitmentRemaining: entityInsights.value.commitmentRemaining,
    hurdleRemaining: entityInsights.value.hurdleRemaining,
  }
})

const callTotals = computed(() => {
  return {
    outstanding: {
      called: entityInsights.value.calledOutstandingTotal,
      capital: entityInsights.value.calledOutstandingCapital,
      managementFee: entityInsights.value.calledOutstandingManagementFee,
      otherFee: entityInsights.value.calledOutstandingOtherFee,
    },
    settled: {
      called: entityInsights.value.calledSettledTotal,
      capital: entityInsights.value.calledSettledCapital,
      managementFee: entityInsights.value.calledSettledManagementFee,
      otherFee: entityInsights.value.calledSettledOtherFee,
    },
  }
})

///////////////////////////////////////////////////////////////////////////////
// Actions
///////////////////////////////////////////////////////////////////////////////

const { entity_id, entity_type, slug } = useRoute().params as { entity_id: string; entity_type: string; slug: string }

const deleteCall = async (id: string) => {
  if (!window.confirm('Are you sure?')) return

  actionsMenu.setLoading('delete', true)
  const callStore = useCallStore()
  const call = filteredCalls.value.find((c) => c.id === id)
  const entityId = route.params.entity_id as string

  await callStore.removeCall(call.id, 'investor-sets', entityId)
  actionsMenu.setLoading('delete', false)

  // refresh calls list
  await fetch(selectedCids.value)
}

const fetch = async (shareholder_cids = null) => {
  showSkeleton()
  await entityStore.fetchCalls(entity_id, { slug, shareholder_cids })
  hideSkeleton()
}

const generateInvoiceForCall = async (callId: string) => {
  await useFetchV3(`/${slug}/investing/b/entities/${entity_id}/calls/${callId}/generate-invoice`).post()
  await fetch(selectedCids.value)
}

const sendInvoiceReminder = async (callId: string) => {
  actionsMenu.setLoading('send_reminder', true)
  await useFetchV3(`/${slug}/investing/b/entities/${entity_id}/calls/${callId}/send-invoice-reminder`).post()
  actionsMenu.setLoading('send_reminder', false)
}

const removeBatchCall = async (batchCall) => {
  if (!window.confirm('Are you sure?')) return

  actionsMenu.setLoading('delete', true)
  await useFetchV2(`/${slug}/investing/${entity_type}/${entity_id}/capital_call/${batchCall.id}/remove`)
    .delete()
    .json<{}>()
  actionsMenu.setLoading('delete', false)
  await fetch(selectedCids.value)
}

const resendInvoices = async (batchCall) => {
  actionsMenu.setLoading('send_reminder', true)
  await useFetchV3(`/${slug}/investing/${entity_type}/${entity_id}/capital_call/${batchCall.id}/resend-invoices`).post()
  actionsMenu.setLoading('send_reminder', false)
}

const onFilter = async () => {
  await fetch(selectedCids.value)
}

const submitInvoicePaymentForm = async () => {
  const valid = await v$.value.$validate()
  if (!valid) return

  loading.value = true
  const payload = {
    paid_at: invoicePaymentForm.value.paid_at,
    instructions: invoicePaymentForm.value.instructions,
    invoice_ids: [invoicePaymentForm.value.invoice_id],
  }
  await useFetchV3(`/${slug}/investing/invoices/mark_multiple_as_paid`).post(payload)
  await fetch(selectedCids.value)
  loading.value = false
  invoicePaymentFormModal.close()

  // reset
  invoicePaymentForm.value = { ...initialState }
  selectedInvoice.value = null
}

const markInvoiceAsUnpaid = async (call) => {
  const confirm = window.confirm('Are you sure you want to mark the invoice as unpaid?')
  if (!confirm) return

  actionsMenu.setLoading('mark_as_unpaid', true)
  await await useFetchV3(`/${slug}/investing/invoices/mark_multiple_as_unpaid`).post({ invoice_ids: [call.invoice_id] })
  actionsMenu.setLoading('mark_as_unpaid', false)
  await fetch(selectedCids.value)
}

const markInvoiceAsPaid = (call) => {
  selectedInvoice.value = expandedCalls.value.find((c) => c.id === call.id).invoice
  invoicePaymentForm.value.invoice_id = call.invoice_id
  invoicePaymentFormModal.open()
}

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const authStore = useAuthStore()
const isAdmin = computed(
  () => authStore.is_site_or_group_admin || !!entityStore.getAdmin(authStore.current_user.investor_id),
)

///////////////////////////////////////////////////////////////////////////////
// Rails Utils
///////////////////////////////////////////////////////////////////////////////

const createInvoiceUrl = (id: string) => {
  return `${rails_url()}/invoices/${id}`
}

///////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////

const { skeleton, hideSkeleton, showSkeleton } = useSkeleton()
const actionsMenu = useActionsMenu(['send_reminder', 'delete', 'mark_as_unpaid'])
const invoicePaymentFormModal = useModal()
const selectedInvoice = ref(null)
const loading = ref(false)

const initialState = {
  invoice_id: null,
  paid_at: null,
  instructions: null,
}
const rules = {
  invoice_id: { required },
  paid_at: { required },
}
const invoicePaymentForm = ref({ ...initialState })
const v$ = useVuelidate(rules, invoicePaymentForm, { $lazy: true })

onMounted(async () => {
  await fetch()
})
</script>

<template>
  <EntityLayout selectedTab="calls">
    <VSection class="flex items-center justify-between">
      <VButtonGroup>
        <VSelectInvestor
          v-model="entityStore.selectedInvestorKeys"
          :investors="entityStore.listShareholders"
          :onClose="onFilter"
        />
        <VButton :active="filters.type === null" class="w-32" @click="setFilter('type', null)">All</VButton>
        <VButton :active="filters.type === 'sent'" class="w-32" @click="setFilter('type', 'sent')">Sent</VButton>
        <VButton :active="filters.type === 'scheduled'" class="w-32" @click="setFilter('type', 'scheduled')"
          >Scheduled</VButton
        >
      </VButtonGroup>
      <RouterLink
        :to="{
          name: 'investing.capital-call.new',
          params: { entity_type: entity?.entity_type, entity_id: entity?.id },
        }"
        v-if="entity"
      >
        <VButton size="md" variant="v-blue">
          <div class="mr-1 flex items-center space-x-2">
            <div><VIcon name="plus" /></div>
            <div>Call capital</div>
          </div>
        </VButton>
      </RouterLink>
    </VSection>
    <div v-if="false">
      <pre>Example of a better filtering system we could implement</pre>
      <label>Min Called</label>
      <input type="number" v-model="filters.minCalled" />
    </div>
    <VSection>
      <CallsBarChart :commitmentTotals="commitmentTotals" :callTotals="callTotals" :skeleton="skeleton" />
    </VSection>
    <VSection>
      <VTable
        :columns="call_columns"
        :items="batchCalls"
        :name="`entities-${1}-calls`"
        sub_item_key="calls"
        :skeleton="skeleton"
        :expand="true"
      >
        <template #shareholder.name="{ item: batchCall }">
          {{ batchCall.name }}
        </template>
        <template #subgroup.shareholder.name="{ item: call }">
          <RouterLink class="hyperlink" :to="investorPath(get(call, 'shareholder.cid'))">
            {{ get(call, 'shareholder.name') }}
          </RouterLink>
        </template>
        <template #type="{ item: batchCall }">
          <VBadge class="inline-flex items-center space-x-1" color="v-green" size="xxs">
            <VIcon name="arrow_narrow_right" class="inline-block h-3 w-3" />
            <span>
              {{ batchCall.type }}
            </span>
          </VBadge>
        </template>
        <template #subgroup.type="{ item: call }">
          <VBadge class="inline-flex items-center space-x-1" color="v-green" size="xxs">
            <VIcon name="arrow_narrow_right" class="inline-block h-3 w-3" />
            <span>
              {{ call.type }}
            </span>
          </VBadge>
        </template>
        <template #subgroup.invoice.display_status="{ item: call }">
          <a
            v-if="call.invoice_id"
            class="hyperlink"
            :class="get(call, 'invoice.display_status') === 'Past Due' ? '!text-red-400 !decoration-red-400/50' : ''"
            :href="createInvoiceUrl(call.invoice_id)"
          >
            {{ get(call, 'invoice.display_status') }}
          </a>
          <VButton
            v-else-if="isAdmin && !call.is_investor_transfer"
            :click="() => generateInvoiceForCall(call.id)"
            size="xs"
            >Generate Invoice</VButton
          >
        </template>
        <template #actions="{ item: batchCall }">
          <ActionsMenu v-if="batchCall.type !== 'transfer call'">
            <ActionsGroup>
              <ActionItem
                tag="RouterLink"
                text="Edit"
                :to="{ name: 'investing.capital-call.edit', params: { capital_call_id: batchCall.id } }"
              />
              <ActionItem
                text="Remove"
                @click="removeBatchCall(batchCall)"
                :loading="actionsMenu.actions.value.delete?.loading"
              />
              <ActionItem
                text="Resend Invoices"
                @click="resendInvoices(batchCall)"
                :loading="actionsMenu.actions.value.send_reminder?.loading"
              />
            </ActionsGroup>
          </ActionsMenu>
        </template>
        <template #subgroup.actions="{ item: call }">
          <ActionsMenu v-if="call.type !== 'transfer call'">
            <ActionsGroup>
              <ActionItem
                tag="RouterLink"
                text="Edit call"
                :to="{
                  name: 'investing.capital-call.edit',
                  params: {
                    capital_call_id: get(call, 'batch_id'),
                    entity_id: entity_id,
                    entity_type: entity_type,
                  },
                }"
              />
              <ActionItem
                v-if="call.invoice_id !== null && !get(call, 'invoice.paid_at')"
                @click="sendInvoiceReminder(call.invoice_id)"
                text="Send invoice reminder"
                :loading="actionsMenu.actions.value.send_reminder?.loading"
              />
              <template v-if="call.invoice_id">
                <ActionItem
                  v-if="!get(call, 'invoice.paid_at')"
                  text="Mark as paid"
                  @click="() => markInvoiceAsPaid(call)"
                />
                <ActionItem
                  v-else
                  text="Mark as unpaid"
                  @click="() => markInvoiceAsUnpaid(call)"
                  :loading="actionsMenu.actions.value.mark_as_unpaid?.loading"
                />
              </template>
            </ActionsGroup>
            <ActionsGroup>
              <ActionItem
                v-if="!call.is_investor_transfer"
                @click="deleteCall(call.id)"
                :loading="actionsMenu.actions.value.delete?.loading"
                text="Delete call"
              />
            </ActionsGroup>
          </ActionsMenu>
        </template>
      </VTable>
    </VSection>
    <VModal :modalStore="invoicePaymentFormModal">
      <template #main>
        <VSection label="Mark invoice as paid">
          <form @submit.prevent="submitInvoicePaymentForm">
            <div class="mt-6 space-y-3">
              <VTextField
                v-model="invoicePaymentForm.paid_at"
                label="Payment date"
                property="paid_at"
                ref="autofocus"
                type="date"
                :v$="v$"
              />
              <VTextArea
                label="Instructions / Notes"
                v-model="invoicePaymentForm.instructions"
                :v$="v$"
                property="instructions"
              />
            </div>
          </form>
        </VSection>
      </template>
      <template #footer>
        <div class="flex items-center justify-between space-x-3">
          <VButton :click="invoicePaymentFormModal.close" size="lg">Close</VButton>
          <VButton :click="submitInvoicePaymentForm" class="w-full" size="lg" :loading="loading" variant="primary">
            Submit
          </VButton>
        </div>
      </template>
    </VModal>
  </EntityLayout>
</template>
