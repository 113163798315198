import { Column } from '@/modules/shared/utils/v-table'

const entity_columns: Column[] = [
  {
    key: 'name',
    name: 'Name',
    type: 'string',
    align: 'left',
    is_visible: true,
    fixed: true,
  },
  {
    key: 'group.name',
    name: 'Group',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'group.subdomain',
    name: 'Group Subdomain',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'legal_name',
    name: 'Legal Name',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  // {
  //   name: 'Entity Status',
  //   key: 'entity_status',
  //   type: 'string',
  //   is_visible: false,
  // },
  {
    key: 'type',
    name: 'Type',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'tax_id',
    name: 'Tax ID',
    type: 'string',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'date_established',
    name: 'Date Established',
    type: 'date',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'document_status',
    name: 'Documents Status',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'amount_invested',
    name: 'Amount Invested',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'capital_committed',
    name: 'Capital Committed',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'capital_called',
    name: 'Capital Called',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'capital_pending',
    name: 'Capital Pending',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'management_fees',
    name: 'Management Fees',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'other_called_fees',
    name: 'Other Called Fees',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'other_disbursement_fees',
    name: 'Other Disburesment Fees',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'total_called',
    name: 'Total Called (with fees)',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'commitment_remaining',
    name: 'Commitment Remaining',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'net_distributed',
    name: 'Total Disbursed',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'carried_interest_distributed',
    name: 'Carried Interest',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'selected_subscriptions',
    csv_key: 'selected_services',
    name: 'Subscriptions',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  // {
  //   key: 'blue_sky_status',
  //   name: 'Blue Sky Status',
  //   type: 'string',
  //   align: 'left',
  //   is_visible: true,
  // },
  {
    key: 'investors',
    name: 'Investors',
    type: 'number',
    aggregate: 'average',
    align: 'right',
    is_visible: true,
  },
  // {
  //   key: 'book_value.beginning_balance',
  //   name: 'Beginning Balance',
  //   type: 'currency',
  //   aggregate: 'sum',
  //   align: 'right',
  //   is_visible: true,
  // },
  // {
  //   key: 'book_value.ending_balance',
  //   name: 'Current Balance',
  //   type: 'currency',
  //   aggregate: 'sum',
  //   align: 'right',
  //   is_visible: true,
  // },
  {
    key: 'address.street1',
    name: 'Street 1',
    type: 'string',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'address.street2',
    name: 'Street 2',
    type: 'string',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'address.city',
    name: 'City',
    type: 'string',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'address.state',
    name: 'State',
    type: 'string',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'address.zip_code',
    name: 'Zip Code',
    type: 'string',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'address.country',
    name: 'Country',
    type: 'string',
    align: 'right',
    is_visible: false,
  },
]

const call_columns: Column[] = [
  {
    key: 'shareholder.name',
    name: 'Investor',
    type: 'string',
    align: 'left',
    fixed: true,
    is_visible: true,
  },
  {
    key: 'due_date',
    name: 'Due Date',
    sorted: true,
    type: 'date',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'type',
    name: 'Type',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'capital',
    name: 'Capital',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'management_fee',
    name: 'Management Fee',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'other_fee',
    name: 'Other Fee',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'called',
    name: 'Total',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'invoice.display_status',
    name: 'Invoice Status',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'actions',
    name: '',
    type: 'actions',
    align: 'right',
    is_visible: true,
  },
]

const distribution_base_columns: Column[] = [
  {
    key: 'shareholder.name',
    name: 'Investor',
    type: 'string',
    align: 'left',
    fixed: true,
    is_visible: true,
  },
  {
    key: 'date',
    name: 'Date',
    sorted: true,
    type: 'date',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'type',
    name: 'Type',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'company.name',
    name: 'Company',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'is_recallable',
    name: 'Recallable',
    type: 'boolean',
    align: 'center',
    is_visible: false,
  },
  {
    key: 'net',
    name: 'Net Distributed',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'return_of_capital',
    name: 'Return of Capital',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'preferred_return',
    name: 'Preferred Return',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'profit',
    name: 'Profit',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'carried_interest',
    name: 'Carried Interest',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'other_fee',
    name: 'Other Fee',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'gross',
    name: 'Gross Distributed',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
]

const distribution_method_columns: Column[] = [
  {
    key: 'investor.disbursement_method_confirmed_at',
    name: 'Payment Method Confirmed At',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_method confirmed by',
    name: 'Payment Method Confirmed By',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_bank_name',
    name: 'Bank Name',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_bank_address',
    name: 'Bank Address',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_bank_routing_number',
    name: 'Bank Routing Number',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_bank_account_number',
    name: 'Bank Account Number',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_bank_swift_code',
    name: 'Bank Swift Code',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_account_name',
    name: 'Account Name',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_wire_for_further_credit_to',
    name: 'For Further Credit To',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_check_receiver_name',
    name: 'Check Receiver Name',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'investor.disbursement_other_details',
    name: 'Other Payment Method Details',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
]

const distribution_columns: Column[] = [
  ...distribution_base_columns,
  {
    key: 'payment_method',
    name: 'Payment Method',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'payment_status',
    name: 'Payment Status',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  ...distribution_method_columns,
  {
    key: 'actions',
    name: '',
    type: 'actions',
    align: 'right',
    is_visible: true,
  },
]

const recycled_distribution_columns: Column[] = [
  ...distribution_base_columns.map((column) => {
    if (column.key === 'net') {
      return {
        ...column,
        name: 'Net Recycled',
      }
    }

    if (column.key === 'gross') {
      return {
        ...column,
        name: 'Gross Recycled',
      }
    }

    return column
  }),
  {
    key: 'actions',
    name: '',
    type: 'actions',
    align: 'right',
    is_visible: true,
  },
]

const addressColumns = ({ keyPrefix = '' }: { keyPrefix?: string } = {}): Column[] => {
  const prefix = keyPrefix ? `${keyPrefix}.` : ''

  const addressAttributes = [
    { key: 'line1', name: 'Line 1' },
    { key: 'line2', name: 'Line 2' },
    { key: 'city', name: 'City' },
    { key: 'state', name: 'State' },
    { key: 'postal_code', name: 'Postal Code' },
    { key: 'country', name: 'Country' },
  ]

  return addressAttributes.map(({ key, name }) => ({
    key: `${prefix}address.${key}`,
    name,
    type: 'string',
    align: 'left',
    is_visible: false,
  }))
}

const shareholder_columns: Column[] = [
  {
    key: 'shareholder.investing_name',
    name: 'Legal Name',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'shareholder.tax_id',
    name: `SSN / EIN`,
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'shareholder.type',
    name: 'Type',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'shareholder.phone',
    name: 'Phone Number',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  ...addressColumns({ keyPrefix: 'shareholder' }),
]

const entity_investor_columns = (isAdmin: boolean): Column[] => [
  {
    key: 'shareholder.name',
    name: 'Investor',
    type: 'string',
    align: 'left',
    fixed: true,
    is_visible: true,
    sorted: true,
  },
  {
    key: 'shareholder.cid',
    name: 'CID',
    type: 'string',
    align: 'left',
    is_visible: false,
    is_accessible: isAdmin,
  },
  {
    key: 'date',
    name: 'Date',
    type: 'date',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'shareholder.email',
    name: 'Email',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'ownership',
    name: 'Ownership',
    type: 'percent',
    align: 'right',
    is_visible: false,
    aggregate: 'sum',
  },
  {
    key: 'ownership_computed',
    name: 'Ownership Computed',
    type: 'percent',
    align: 'right',
    is_visible: false,
    is_accessible: isAdmin,
  },
  {
    key: 'ownership_override',
    name: 'Ownership Override',
    type: 'percent',
    align: 'right',
    is_visible: false,
    is_accessible: isAdmin,
  },
  {
    key: 'ownership_by_commitment',
    name: 'Ownership by Commitment',
    type: 'percent',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'committed',
    name: 'Committed',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'capital_called',
    name: 'Capital',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'management_fee_called',
    name: 'Management Fee',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'other_fee_called',
    name: 'Other Fee',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'called',
    name: 'Total Called',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'commitment_remaining',
    name: 'Commitment Remaining',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'management_fee_percentage',
    name: 'Management Fee',
    type: 'percent',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'carried_interest_percentage',
    name: 'Carried Interest',
    type: 'percent',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'preferred_return_percentage',
    name: 'Preferred Return',
    type: 'percent',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'net_distributed',
    name: 'Distributed',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'fees_called',
    name: 'Total Fee',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: false,
  },
  {
    key: 'cash_position',
    name: 'Cash Position',
    type: 'currency',
    aggregate: 'sum',
    align: 'right',
    is_visible: false,
  },
  ...shareholder_columns,
  {
    key: 'actions',
    name: '',
    type: 'actions',
    align: 'right',
    is_visible: true,
  },
]

const individual_entity_columns: Column[] = [
  {
    key: 'name',
    name: 'Name',
    type: 'string',
    align: 'left',
    is_visible: true,
    fixed: true,
  },
  {
    key: 'insights.committed',
    name: 'Committed',
    type: 'currency',
    align: 'right',
    is_visible: true,
    aggregate: 'sum',
  },
  {
    key: 'insights.called_capital',
    name: 'Capital',
    type: 'currency',
    align: 'right',
    is_visible: true,
    aggregate: 'sum',
  },
  {
    key: 'insights.called_management_fee',
    name: 'Management Fees',
    type: 'currency',
    align: 'right',
    is_visible: true,
    aggregate: 'sum',
  },
  {
    key: 'insights.called_other_fee',
    name: 'Other Fees',
    type: 'currency',
    align: 'right',
    is_visible: true,
    aggregate: 'sum',
  },
  {
    key: 'insights.called_total',
    name: 'Total Called',
    type: 'currency',
    align: 'right',
    is_visible: true,
    aggregate: 'sum',
  },
  {
    key: 'insights.commitment_remaining',
    name: 'Commitment Remaining',
    type: 'currency',
    align: 'right',
    is_visible: true,
    aggregate: 'sum',
  },
  {
    key: 'insights.distributed_net',
    name: 'Net Distributed',
    type: 'currency',
    align: 'right',
    is_visible: true,
    aggregate: 'sum',
  },
  {
    key: 'insights.distributed_carried_interest',
    name: 'Carried Interest',
    type: 'currency',
    align: 'right',
    is_visible: false,
    aggregate: 'sum',
  },
  {
    key: 'insights.cash_position',
    name: 'Cash Position',
    type: 'currency',
    align: 'right',
    is_visible: false,
    aggregate: 'sum',
  },
  {
    key: 'legal_name',
    name: 'Legal Name',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'date_established',
    name: 'Date Established',
    type: 'date',
    align: 'right',
    is_visible: true,
  },
  {
    key: 'group.name',
    name: 'Group',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'group.subdomain',
    name: 'Subdomain',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'type',
    name: 'Legal Structure',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'tax_id',
    name: 'EIN',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'document_status',
    name: 'Document Status',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'selected_subscriptions',
    csv_key: 'selected_services',
    name: 'Subscription',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'admins',
    name: 'Managers',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
  {
    key: 'address.line1',
    name: 'Line 1',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'address.line2',
    name: 'Line 2',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'address.city',
    name: 'City',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'address.state',
    name: 'State',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'address.zip',
    name: 'Postal Code',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
  {
    key: 'address.country',
    name: 'Country',
    type: 'string',
    align: 'left',
    is_visible: false,
  },
]

const individual_other_entity_columns: Column[] = [
  {
    key: 'name',
    name: 'Name',
    type: 'string',
    align: 'left',
    is_visible: true,
    fixed: true,
  },
  {
    key: 'admins',
    name: 'Admins',
    type: 'string',
    align: 'left',
    is_visible: true,
  },
]

const individual_invoice_columns = (isAdmin: boolean, hidden_columns = []): Column[] => [
  {
    key: 'checkbox',
    name: '',
    type: 'string',
    align: 'left',
    is_visible: true,
    is_accessible: isAdmin,
    enable_thead_slot: true,
  },
  {
    key: 'name',
    name: 'Name',
    type: 'string',
    align: 'left',
    fixed: true,
    is_visible: !hidden_columns.includes('name'),
  },
  {
    key: 'investor.name',
    name: 'Investor',
    type: 'string',
    align: 'left',
    is_visible: !hidden_columns.includes('investor'),
  },
  {
    key: 'date',
    name: 'Due Date',
    type: 'date',
    sorted: true,
    align: 'left',
    is_visible: !hidden_columns.includes('date'),
  },
  {
    key: 'marked_as_paid',
    name: 'Status',
    type: 'boolean',
    align: 'left',
    is_visible: !hidden_columns.includes('status'),
  },
  {
    key: 'total_amount',
    name: 'Amount Due',
    type: 'currency',
    align: 'right',
    is_visible: !hidden_columns.includes('total_amount'),
    aggregate: 'sum',
  },
  {
    key: 'paid_at',
    name: 'Paid Date',
    type: 'date',
    align: 'left',
    is_visible: !hidden_columns.includes('paid_at'),
  },
  {
    key: 'actions',
    name: '',
    type: 'actions',
    align: 'right',
    is_visible: isAdmin,
  },
]

export {
  addressColumns,
  call_columns,
  entity_columns,
  distribution_columns,
  distribution_method_columns,
  entity_investor_columns,
  individual_entity_columns,
  individual_invoice_columns,
  individual_other_entity_columns,
  recycled_distribution_columns,
  shareholder_columns,
}
