import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useFetch } from '@/modules/core/composables/useFetch'
import { Company, EntityExpanded, Industry, Investment, Investor, InvestorType, TermSheet } from './better-entity-store'

export interface Shareholder {
  id: string
  cid: string
  name: string
  type: string
}

export interface IdentityLog {
  id: string
  aml_status: string
  is_whitelisted: boolean
  match_status: string
  share_url: string
  updated_at: string
}

export interface Individual {
  id: string
  accredited_status: string
  companies: Company[]
  currency: string
  disbursement_method: string
  disbursement_method_confirmed_at: string
  disbursement_method_confirmed_by: string
  disbursement_wire_bank_name: string
  disbursement_wire_bank_address: string
  disbursement_wire_bank_routing_number: string
  disbursement_wire_bank_account_number: string
  disbursement_wire_bank_swift_code: string
  disbursement_wire_account_name: string
  disbursement_wire_for_further_credit_to: string
  disbursement_check_receiver_name: string
  disbursement_other_details: string
  email: string
  entity_ids: string[]
  identity_log: IdentityLog
  industries: Industry[]
  initials: string
  insights: any
  name: string
  other_entity_ids: string[]
  profile_picture: string
  profile_status: string
}

export interface OtherEntityExpanded {}
export interface Invoice {
  id: string
  marked_as_paid: boolean
}

export interface IndividualExpanded extends Individual {
  entities: EntityExpanded[]
  investments: Investment[]
  invoices: Invoice[]
  other_entities: OtherEntityExpanded[]
  shareholders: Shareholder[]
  term_sheets: TermSheet[]
}

export const useIndividualStore = defineStore('individualStore', () => {
  const individual = ref<IndividualExpanded>(null)

  /////////////////////////////////////////////////////////////////////////////
  // Actions
  /////////////////////////////////////////////////////////////////////////////

  const fetchIndividual = async (
    id: string,
    { slug, shareholder_cids }: { slug: string; shareholder_cids?: string[] },
  ) => {
    const params = new URLSearchParams()
    if (shareholder_cids?.length) {
      params.set('shareholder_cids', shareholder_cids.join(','))
    }

    const queryParams = params.toString() ? `?${params}` : ''
    const data = await useFetch(`/v4/${slug}/investing/individuals/${id}${queryParams}`).get<IndividualExpanded>()
    individual.value = data
  }

  const fetchEntities = async (
    entityIds: string[],
    { slug, shareholder_cids }: { slug: string; shareholder_cids?: string[] },
  ) => {
    const params = new URLSearchParams()
    if (shareholder_cids?.length) {
      params.set('shareholder_cids', shareholder_cids.join(','))
    }

    const queryParams = params.toString() ? `?${params}` : ''
    try {
      individual.value.entities = await Promise.all(
        entityIds.map((entityId) =>
          useFetch(`/v4/${slug}/investing/investor-sets/${entityId}${queryParams}`).get<EntityExpanded>(),
        ),
      )
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchOtherEntities = async (
    otherEntityIds: string[],
    { slug, shareholder_cids }: { slug: string; shareholder_cids?: string[] },
  ) => {
    const params = new URLSearchParams()
    if (shareholder_cids?.length) {
      params.set('shareholder_cids', shareholder_cids.join(','))
    }

    const queryParams = params.toString() ? `?${params}` : ''
    try {
      individual.value.other_entities = await Promise.all(
        otherEntityIds.map((otherEntityId) =>
          useFetch(`/v4/${slug}/investing/other-entities/${otherEntityId}${queryParams}`).get<OtherEntityExpanded>(),
        ),
      )
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchInvoices = async (
    individualId: string,
    { slug, shareholder_cids }: { slug: string; shareholder_cids?: string[] },
  ) => {
    const params = new URLSearchParams()
    if (shareholder_cids?.length) {
      params.set('shareholder_cids', shareholder_cids.join(','))
    }

    const queryParams = params.toString() ? `?${params}` : ''
    const data = await useFetch(`/v4/${slug}/investing/individuals/${individualId}/invoices${queryParams}`).get<
      Invoice[]
    >()
    individual.value.invoices = data
  }

  const fetchInvestments = async (
    individualId: string,
    { slug, shareholder_cids }: { slug: string; shareholder_cids?: string[] },
  ) => {
    const params = new URLSearchParams()
    if (shareholder_cids?.length) {
      params.set('shareholder_cids', shareholder_cids.join(','))
    }

    const queryParams = params.toString() ? `?${params}` : ''
    const investments = await useFetch(
      `/v4/${slug}/investing/individuals/${individualId}/investments${queryParams}`,
    ).get<Investment[]>()
    individual.value.investments = investments
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  const createGet = <T>(key: string) => {
    return (id: string): T => {
      return individual.value?.[key]?.find((item: T) => item.id.toString() === id.toString())
    }
  }
  const createList = <T>(key: string): T[] => individual.value?.[key] ?? []
  const createComputedList = <T>(key: string) => computed<T[]>(() => createList<T>(key))

  ///////////////////////////////////////////////////////////////////////////////
  // Companies
  ///////////////////////////////////////////////////////////////////////////////

  const getCompany = createGet<Company>('companies')
  const listCompanies = createComputedList<Company>('companies')

  ///////////////////////////////////////////////////////////////////////////////
  // Entities
  ///////////////////////////////////////////////////////////////////////////////

  const listEntities = createComputedList<EntityExpanded>('entities')

  ///////////////////////////////////////////////////////////////////////////////
  // Industries
  ///////////////////////////////////////////////////////////////////////////////

  const getIndustry = createGet<Industry>('industries')
  const listIndustries = createComputedList<Industry>('industries')

  ///////////////////////////////////////////////////////////////////////////////
  // Investments
  ///////////////////////////////////////////////////////////////////////////////

  const getInvestment = createGet<Investment>('investments')
  const listInvestments = createComputedList<Investment>('investments')

  ///////////////////////////////////////////////////////////////////////////////
  // Invoices
  ///////////////////////////////////////////////////////////////////////////////

  const getInvoice = createGet<Invoice>('invoices')
  const listInvoices = createComputedList<Invoice>('invoices')

  ///////////////////////////////////////////////////////////////////////////////
  // Other Entities
  ///////////////////////////////////////////////////////////////////////////////

  const listOtherEntities = createComputedList<EntityExpanded>('other_entities')

  ///////////////////////////////////////////////////////////////////////////////
  // Shareholders
  ///////////////////////////////////////////////////////////////////////////////

  const selectedShareholderKeys = ref<string[]>([])
  const getShareholder = (id: string, type: InvestorType): Investor => {
    return listShareholders.value?.find((item) => item.id === id && item.type === type)
  }
  const listShareholders = createComputedList<Investor>('shareholders')

  ///////////////////////////////////////////////////////////////////////////////
  // TermSheets
  ///////////////////////////////////////////////////////////////////////////////

  const getTermSheet = createGet<TermSheet>('term_sheets')
  const listTermSheets = createComputedList<TermSheet>('term_sheets')

  ///////////////////////////////////////////////////////////////////////////////

  return {
    // State
    individual,

    // Actions
    fetchIndividual,
    fetchEntities,
    fetchInvestments,
    getInvoice,
    fetchInvoices,
    fetchOtherEntities,

    // Getters

    listShareholders,
    getShareholder,
    selectedShareholderKeys,

    getCompany,
    listCompanies,

    getIndustry,
    listIndustries,

    getInvestment,
    listInvestments,

    getTermSheet,
    listTermSheets,

    listEntities,
    listInvoices,
    listOtherEntities,
  }
})
